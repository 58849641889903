import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.1_@babel+core@7.24.5_@playwright+test@1.48.1_react-dom@19.0.0-rc-65a56d0e-20241020__iy7nmk2yrcjsycrbwrafecwx5a/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.1_@babel+core@7.24.5_@playwright+test@1.48.1_react-dom@19.0.0-rc-65a56d0e-20241020__iy7nmk2yrcjsycrbwrafecwx5a/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.1_@babel+core@7.24.5_@playwright+test@1.48.1_react-dom@19.0.0-rc-65a56d0e-20241020__iy7nmk2yrcjsycrbwrafecwx5a/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.1_@babel+core@7.24.5_@playwright+test@1.48.1_react-dom@19.0.0-rc-65a56d0e-20241020__iy7nmk2yrcjsycrbwrafecwx5a/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.1_@babel+core@7.24.5_@playwright+test@1.48.1_react-dom@19.0.0-rc-65a56d0e-20241020__iy7nmk2yrcjsycrbwrafecwx5a/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.1_@babel+core@7.24.5_@playwright+test@1.48.1_react-dom@19.0.0-rc-65a56d0e-20241020__iy7nmk2yrcjsycrbwrafecwx5a/node_modules/next/dist/client/components/render-from-template-context.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.1_@babel+core@7.24.5_@playwright+test@1.48.1_react-dom@19.0.0-rc-65a56d0e-20241020__iy7nmk2yrcjsycrbwrafecwx5a/node_modules/next/dist/lib/metadata/metadata-boundary.js");
